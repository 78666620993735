<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="register" method="POST">
                <h1>Registro</h1>
                <p class="text-muted">Crear mi nueva cuenta</p>
                <CInput
                    placeholder="Nombre completo"
                    prependHtml="<i class='cui-user'></i>"
                    autocomplete="username"
                    v-model="name"
                    required
                >
                  <template #prepend-content>
                    <CIcon name="cil-user"/>
                  </template>
                </CInput>
                <CInput
                    placeholder="Nombre de usuario"
                    title="nombre.apellido"
                    prepend="@"
                    autocomplete="email"
                    v-model="email"
                    required
                />
                <CInput
                    placeholder="Contraseña"
                    type="password"
                    prependHtml="<i class='cui-lock-locked'></i>"
                    autocomplete="new-password"
                    v-model="password"
                    required
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                </CInput>
                <CInput
                    placeholder="Repetir contraseña"
                    type="password"
                    prependHtml="<i class='cui-lock-locked'></i>"
                    autocomplete="new-password"
                    class="mb-4"
                    v-model="password_confirmation"
                    required
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                </CInput>
                <CButton type="submit" color="success" >Crear Cuenta</CButton>
                <CButton color="outline-dark" @click="goBack" class="ml-5">Regresar a la pantalla anterior</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/users' });
    },
    register() {
      var self = this;
      axios.post(this.$apiAdress + '/api/register', {
        name: self.name,
        email: self.email.includes("@") ? self.email : self.email + '@ub.edu.bo',
        password: self.password,
        password_confirmation: self.password_confirmation
      }).then(function (response) {
        self.name = '';
        self.email = '';
        self.password = '';
        self.password_confirmation = '';
        self.goBack()
      })
          .catch(function (error) {
            console.log(error);
          });

    }
  }
}

</script>
